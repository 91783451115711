


import React, { useContext, useState, useEffect } from "react"
import { Config } from "../config/Config"


const AuthAdminContext = React.createContext()
let config = process.env.REACT_APP_SERVER === 'dev' ? Config.DEV : process.env.REACT_APP_SERVER === 'prod' ? Config.PROD : Config.LOCAL;

export function useAuthAdmin() {
  return useContext(AuthAdminContext)
}

export function AuthAdminProvider({ children }) {


  const [loading, setLoading] = useState(false)
  const [requestSuccess, setLoadingSuccessData] = useState()
  const [requestError, setRequestError] = useState()
  const [currentUser, setCurrentUser] = useState()

  var loggedInUser


  useEffect(() => {

    setLoadingSuccessData()

    const loggedInUser = localStorage.getItem("appkey.io.user");
    if (loggedInUser) {
      try {
        const foundUser = JSON.parse(loggedInUser);
        if (foundUser && foundUser.isAdmin) setCurrentUser(foundUser)
        else setCurrentUser()

      } catch (error) {

      }

    }

  }, [])

  async function apiRequest(method, path, data, showLoading = true, file) {

    const userCache = localStorage.getItem("appkey.io.user");

    if (userCache) {
      loggedInUser = JSON.parse(userCache);
    }

    if (!loggedInUser || !loggedInUser.isAdmin) return;

    setRequestError(null)
    if (showLoading) setLoading(true)

    try {
      let requestOptions = {
        method: method || 'POST',
        headers: {
          "access-token": loggedInUser && loggedInUser["access-token"] ? loggedInUser["access-token"] : ""
        }
      };

      if (method !== "GET" && method !== "DELETE" && data) {
        requestOptions.body = JSON.stringify(data)
      }

      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        requestOptions.body = formData
      }
      else {
        requestOptions.headers['Content-Type'] = 'application/json'
      }

      let endpoint = `${config.REST_API}/api/${path}`

      console.log("apiRequest endpoint ", endpoint)

      const response = await fetch(endpoint, requestOptions);
      let result = await response.json();

      console.log("apiRequest result ", result)

      if (response.status !== 200) {
        setRequestError(result);
        if (result.code === 405) logout()

        return { error: result }
      }
      else {
        return result;
      }


    } catch (error) {
      console.log("apiRequest error ", error)
      setRequestError(error)
      return { error: error }
    }
    finally {
      setLoading(false)
    }
  }



  async function getApps(filter) {
    let query = `?search=${filter.search}&offset=${filter.offset}&limit=${filter.limit}`
    let response = await apiRequest("GET", `appkey/apps${query}`, null, true)
    return response;
  }



  async function getUsers(filter) {

    let query = `?search=${filter.search}&offset=${filter.offset}&limit=${filter.limit}`
    let response = await apiRequest("GET", `appkey/users${query}`, null, true)
    return response;
  }



  async function getSignups(filter) {
    let query = `?search=${filter.search}&offset=${filter.offset}&limit=${filter.limit}`
    let response = await apiRequest("GET", `appkey/signups${query}`, null, true)
    return response;
  }



  async function updateUser(data) {

    let response = await apiRequest("POST", `appkey/updateUserStatus`, data, true)
    return response;
  }



  async function resetUserPasskey(data) {

    let response = await apiRequest("POST", `appkey/resetUserPasskey`, data, true)
    return response;
  }


  async function getUserApps(user) {

    let response = await apiRequest("GET", `appkey/userapps?userId=${user.userId}`, null, true)
    return response;
  }



  function isLoggedIn() {
    try {
      const catche = localStorage.getItem("appkey.io.user");
      let loggedInUser = JSON.parse(catche);
      if (loggedInUser && loggedInUser['access-token']) {
        setCurrentUser(loggedInUser)
        return true
      }
      else return false
    } catch (error) {
      return false
    }
  }

  function logout() {

    setCurrentUser();

    localStorage.clear();
    return true;
  }


  const verify = async () => {
    let result = await apiRequest("POST", `authn/verify`, {}, true)
    return result
  }

  const verifyComplete = async (data) => {
    let result = await apiRequest("POST", `authn/verifyComplete`, data, true)

    if (result && !result.error) {
      localStorage.setItem('appkey.io.user', JSON.stringify(result));
      setCurrentUser(result)
    }
    return result
  } 

  const value = {
    config,
    currentUser,
    requestSuccess,
    requestError,
    loading,
    isLoggedIn,
    verify,
    verifyComplete,
    setLoadingSuccessData,
    setRequestError,
    getUsers,
    getSignups,
    getUserApps,
    getApps,
    updateUser,
    resetUserPasskey
  }

  return (
    <AuthAdminContext.Provider value={value}>
      {children}
    </AuthAdminContext.Provider>
  )
}
