/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import Modal from "@mui/material/Modal";
import MKButton from "components/MKButton"; 
import MKTypography from "components/MKTypography"; 
import CloseIcon from "@mui/icons-material/Close";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import Divider from "@mui/material/Divider";
import MKAlert from "components/MKAlert";
import CircularProgress from '@mui/material/CircularProgress';
// Material Kit 2 PRO React example components
import DefaultNavbar from "components/DefaultNavbar";
import Footer from "components/Footer";
import {  useNavigate } from "react-router-dom"
// Material kit 2 PRO React page layout routes
import routes from "routes";
 
import { useAuth } from "../../../contexts/AuthContext"
import { useState, useEffect } from "react";

function AdminLayout({ children }) {

    const { requestError, setRequestError, loading, requestSuccess, setLoadingSuccessData, isLoggedIn, logout} = useAuth()

    const navigate = useNavigate()

    const loggedInUser = JSON.parse(localStorage.getItem("appkey.io.user"));  
    const [showAlertPopup, setAlertPopup] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    useEffect(() => {

      if (!isLoggedIn())  navigate("/signout")
      
    }, []) 

    useEffect(() => { 
        if(requestError && requestError.message) toggleAlert()
        if(requestError && requestError.code === 501) navigate("/signout")
          
    }, [requestError]) 

    useEffect(() => { 
      
      if(requestSuccess && requestSuccess.message){
        toggleSuccess()
      }
    }, [requestSuccess]) 

    const toggleSuccess = () => {
      setShowSuccessPopup(!showSuccessPopup)

    }

    const toggleCloseSuccess = () => {
      setShowSuccessPopup(!showSuccessPopup)
      setLoadingSuccessData(null)
    }

    const toggleAlert = () => {
      setAlertPopup(!showAlertPopup)
    }

    const closeAlertMessage = () => {
      setAlertPopup(!showAlertPopup)
      setRequestError(null)
    }



    return (
        <MKBox component="section" >
            <MKBox width="100%" >
                <DefaultNavbar
                brand = "AppKey"
                routes={routes.loggedInRoute}  
                transparent
                dark
                relative
                />
            </MKBox>
        
            <MKBox width="100%">
                <Grid container  justifyContent="center"  mx="auto" >
                    <Grid item xs={11} sm={12} md={12} lg={12} xl={10}>
                        {children}
                    </Grid> 
                </Grid> 
            </MKBox>
            <MKBox width="100%" pt={12}>
                <Footer />
            </MKBox>

                                          
        <Modal open={showAlertPopup} onClose={toggleAlert} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={showAlertPopup} timeout={300}>
            <Grid container item xs={12} lg={5} justifyContent="center" mx="auto">
              <MKBox
                position="relative"
                sx={{ width: '95%' }}
                display="flex"
                flexDirection="column"
                borderRadius="xl"
                bgColor="white"
                shadow="xl"
              >
                <MKBox display="flex" alignitems="center" justifyContent="space-between" p={2}>
                  <MKTypography variant="h5">Request Failed</MKTypography>
                  <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={closeAlertMessage} />
                </MKBox>
                <Divider sx={{ my: 0 }} />
                
                <MKBox p={2}>
                  {requestError  && <MKAlert color="error" >Whoop! {requestError.message}</MKAlert>}
                </MKBox>

                <MKBox display="flex" justifyContent="space-between" p={1.5}>
                  
                  <MKButton variant="gradient" color="primary" onClick={closeAlertMessage}>
                    Close
                  </MKButton>
                </MKBox>
              </MKBox>
            </Grid>
          </Slide>
        </Modal>

        <Modal open={loading} sx={{ display: "grid", placeItems: "center", outline:0 }} hideBackdrop={true}>
          
            <MKBox display="flex" justifyContent="center" p={2}>
              <CircularProgress  color="primary"/> 
            </MKBox>

          
        </Modal>

        <Modal open={showSuccessPopup}  onClose={toggleSuccess} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={showSuccessPopup} timeout={300}>
          <Grid container item xs={12} lg={5} justifyContent="center" mx="auto">
          { requestSuccess  && 
            <MKBox
              position="relative"
              sx={{ width: '95%', minHeight: 300 }}
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              
              <MKBox display="flex" alignitems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h4" color="info">{ requestSuccess.title ? requestSuccess.title : "Request Success"}</MKTypography>
                 
              </MKBox>
              
              <Divider sx={{ my: 1 }} />
              
              <MKBox display="flex" justifyContent="center" p={2}>
                 <MKTypography variant="h5">{requestSuccess.message }</MKTypography>  
              </MKBox> 

              <Divider sx={{ my: 2 }} /> 

              <MKBox display="flex" justifyContent="right" p={1.5}>
                <MKButton variant="gradient" color="info" onClick={toggleCloseSuccess}>
                  close
                </MKButton> 
              </MKBox>

            </MKBox>
          }
            </Grid>
          </Slide>
        </Modal>



        </MKBox>

        
    );
}
 

// Typechecking props for the AdminLayout
AdminLayout.propTypes = {
  coverHeight: PropTypes.string, 
  children: PropTypes.node.isRequired,
};

export default AdminLayout;
