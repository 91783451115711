



import React, { useContext, useState, useEffect } from "react"
import { Config } from "../config/Config"


const AuthContext = React.createContext()
let config = process.env.REACT_APP_SERVER === 'dev' ? Config.DEV : process.env.REACT_APP_SERVER === 'prod' ? Config.PROD : Config.LOCAL;

export function appAuth() {
    return useContext(AuthContext)
}

export function AppProvider({ children }) {


    const [loading, setLoading] = useState(false)
    const [allApps, setAllApps] = useState([])
    const [currentApp, setCurrentApp] = useState()


    const [loadingSuccessData, setLoadingSuccessData] = useState()
    const [alertMessage, setAlertMessage] = useState()
    const [requestError, setRequestError] = useState()
    const [requestSuccess, setRequestSuccess] = useState()
    const [loggedInUser, setLoggedInUser] = useState()


    async function checkURL(url) {
        let requestOptions = {
            method: 'GET',
        };

        console.log("checkURL url ", url)

        const response = await fetch(url, requestOptions);

        console.log("apiRequest response ", response)
        let result = await response.json();

        return result
    }

    async function apiRequest(method, path, data, showLoading = true, file) {

        try {
            const catche = localStorage.getItem("appkey.io.user");
            let user = JSON.parse(catche);
            setLoggedInUser(user);

            console.log("apiRequest path ", path)

            if (!user || user["access-token"] === undefined) {
                console.log("apiRequest Invalid user ", user)

                //setAlertMessage({message: "Invalid User, Please logout and login again."})
                return
            }


            setRequestError(null)
            if (showLoading) setLoading(true)

            let requestOptions = {
                method: method || 'POST',
                headers: {
                    "access-token": user["access-token"]
                }
            };


            if (file) {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('appName', data.appName);
                requestOptions.body = formData;
               

            }
            else {
                requestOptions.headers['Content-Type'] = 'application/json';

                if (method !== "GET" && method !== "DELETE") {
                    requestOptions.body = JSON.stringify(data)
                }
            }

 

            let endpoint = `${config.REST_API}/api/${path}`
            const response = await fetch(endpoint, requestOptions);
            let result = await response.json();

            console.log("apiRequest result ", result)


            if (response.status !== 200) {
                setRequestError(result);
                return { error: result }
            }
            else {
                return result;
            }


        } catch (error) {

            setRequestError(error)
            return { error: error }
        }
        finally {
            setLoading(false)
        }

    }

    async function createApp(name) {

        let checked = allApps.filter(app => app.name == name)[0];
        if (checked) {
            return { error: { message: `App name '${name}' already exists.` } }
        }

        let temp = { name: name, status: "pending", appId: name }
        setAllApps(prevItems => {
            return [temp, ...prevItems];
        });

        let data = await apiRequest("POST", "app/create", { name: name }, false)

        setAllApps(
            allApps.filter(app =>
                app.name !== data.name
            )
        );

        if (!data.error) {
            setAllApps(prevItems => {
                return [data, ...prevItems];
            });
        }

        setRequestError(null);

        return data;
    }

    async function updateApp(appId, option, value) {
        console.log("updateApp updated option", option)

        let requestData = { appId: appId }
        requestData[option] = value
        requestData.option = option


        let data = await apiRequest("POST", "app/update", requestData, false)


        if (data.error) return data
        else {
            console.log("updateApp Success updated")

            setRequestSuccess({ message: "Successful updated." })

            let list = allApps.map(app => {
                if (app.appId === appId) {
                    return { ...app, [option]: value };
                } else {
                    // No changes
                    return app;
                }
            })


            setAllApps(list);

            setCurrentApp({
                ...currentApp,
                [option]: value
            })

            return list;
        }
    }

    async function resetApp(appId) {
        let data = await apiRequest("POST", "app/resetApp", { appId: appId })
        if (data.error) return data
        else return true
    }



    async function appLogs(filter) {
        let query = ""
        if (filter.type && filter.type.length) {
            query += `&type=${filter.type.toString().toLowerCase()}`
        }

        if (filter.search && filter.search.length) {
            query += `&search=${filter.search.toString().toLowerCase()}`
        }

        if (filter.filterAction && filter.filterAction.length) {
            query += `&filterAction=${filter.filterAction.toString().toLowerCase()}`
        }

        if (filter.status && filter.status.length) {
            query += `&status=${filter.status.toString().toLowerCase()}`
        }

        if (filter.fromDate) {
            query += `&fromDate=${filter.fromDate}`
        }

        if (filter.toDate) {
            query += `&toDate=${filter.toDate}`
        }

        if (filter.limit) {
            query += `&limit=${filter.limit}`
        }

        if (filter.offset) {
            query += `&offset=${filter.offset - 1}`
        }

        let data = await apiRequest("GET", `app/logs?appId=${filter.appId}${query}`)
        if (data.error) setAlertMessage(data.error)
        else {
            return data
        }

    }


    async function deleteApp(appId) {

        let data = await apiRequest("DELETE", `app/deleteApp/${appId}`)
        if (data.error) return data
        else {
            setCurrentApp()
            return true
        }
    }


    async function getAllApp() {
 
        let data = await apiRequest("GET", "app/allApps", {})
        if (data && data.error) {
            setAllApps([])
            return null
        }
        else if (data) {
            setAllApps(data);
            return data
        }


    }

    const testAppEmailExt = async (appId, email) => {

        let data = await apiRequest("POST", "app/testAppEmailExt", { appId: appId, toEmail: email })

        if (data.error) setAlertMessage(data.error)
        else return true
    }


    const testAppSMSExt = async (appId, number) => {

        let data = await apiRequest("POST", "app/testAppSMSExt", { appId: appId, toNumber: number })

        if (data.error) setAlertMessage(data.error)
        else return true
    }


    const getApp = async (appId) => {


        if (allApps.length > 0) {
            let app = allApps.filter(item => item.appId == appId)[0]

            console.log("getApp data = ", app)
            setCurrentApp(app)
            return app
        }
        else {
            console.log("getAllApp ")

            let apps = await getAllApp();
            if (!apps) {
                setAlertMessage("Invalid App ID")
                return null
            }
            else {
                let app = apps.filter(item => item.appId == appId)[0]
                setCurrentApp(app)
                return app
            }
        }


    }



    const createAppInvite = async (appId, values) => {

        let result = await apiRequest("POST", "app/createAppSignupInvite", { appId: appId, emails: values })

        if (result.error) setAlertMessage(result.error)
        else {
            let message = ""
            if (result.invites && result.invites.length) {

                message = `Your invitation has been sent to: ${result.invites}.`;

                if (result.exists && result.exists.length) {
                    if (result.exists.length > 1) message = message + `<br/>These emails already registered: ${result.exists}.`
                    else message = message + `<br/>This email already registered: ${result.exists}.`
                }

            }
            else if (result.exists && result.exists.length) {
                if (result.exists.length > 1) message = message + `These emails already registered: ${result.exists}.`
                else message = message + `This email already registered: ${result.exists}.`
            }

            setLoadingSuccessData({ message: message })
        }

        return result
    }

    const getAppEmailTemplate = async (appId) => {
        let result = await apiRequest("GET", `app/emailTemplates?appId=${appId}`)
        if (result.error) {
            setAlertMessage(result.error)
            return null
        }
        else {
            return result
        }
    }

    const updateEmailTemplate = async (template) => {
        let result = await apiRequest("POST", `app/updateEmailTemplate`, template, false)
        if (result.error) {
            setAlertMessage(result.error)
            return null
        }
        else {
            return result
        }
    }


    const updateAppLocale = async (data) => {
        let result = await apiRequest("POST", `app/updateAppLocale`, data, false)
        if (result.error) {
            setAlertMessage(result.error)
            return null
        }
        else {
            return result
        }
    }


    const getAppUser = async (filter) => {

        let query = ""
        if (filter.search) {
            query += `&search=${filter.search}`
        }

        if (filter.fromDate) {
            query += `&fromDate=${filter.fromDate}`
        }

        if (filter.toDate) {
            query += `&toDate=${filter.toDate}`
        }

        if (filter.limit) {
            query += `&limit=${filter.limit}`
        }

        if (filter.offset) {
            query += `&offset=${filter.offset - 1}`
        }

        let result = await apiRequest("GET", `app/appuser?appId=${currentApp.appId}${query}`)
        if (result.error) {
            setAlertMessage(result.error)
            return null
        }
        else {
            return result
        }
    }


    const updateAppUserStatus = async (user, status) => {
        let result = await apiRequest("POST", `app/updateAppUserStatus`, { handle: user.handle, appId: user.appId, status: status })
        if (result.error) {
            setAlertMessage(result.error)
            return null
        }
        else {
            return result
        }
    }


    const deleteAppUser = async (data) => {
        let result = await apiRequest("POST", `app/deleteAppUser`, { handle: data.handle, appId: data.appId })
        if (result.error) {
            setAlertMessage(result.error)
            return null
        }
        else {
            return result
        }
    }

    const getAppSignup = async (filter) => {

        let query = ""
        if (filter.search) {
            query += `&search=${filter.search}`
        }

        if (filter.fromDate) {
            query += `&fromDate=${filter.fromDate}`
        }

        if (filter.toDate) {
            query += `&toDate=${filter.toDate}`
        }

        if (filter.limit) {
            query += `&limit=${filter.limit}`
        }

        if (filter.offset) {
            query += `&offset=${filter.offset - 1}`
        }


        let result = await apiRequest("GET", `app/signup?appId=${currentApp.appId}${query}`)
        if (result.error) {
            setAlertMessage(result.error)
            return null
        }
        else {
            return result
        }
    }

    const deleteAppSignup = async (data) => {
        let result = await apiRequest("POST", `app/deleteAppSignup`, { handle: data.handle, appId: data.appId })
        if (result.error) {
            setAlertMessage(result.error)
            return null
        }
        else {
            return result
        }
    }

    const verifyHandle = async (data) => {
        let result = await apiRequest("POST", `app/verifyHandle`, data)
        if (result.error) {
            setAlertMessage(result.error)
        }
        return result
    }

    const createAppSignup = async (data) => {
        let result = await apiRequest("POST", `app/createAppSignup`, data)
        if (result.error) {
            setAlertMessage(result.error)
        }
        return result
    }


    const createUserSignup = async (data) => {
        let result = await apiRequest("POST", `appuser/signup`, data)
        if (result.error) {
            setAlertMessage(result.error)
        }

        return result
    }

    const verifiyUserSignup = async (data) => {
        let result = await apiRequest("POST", `appuser/signupConfirm`, data)
        if (result.error) {
            setAlertMessage(result.error)
        }

        return result
    }

    const signupComplete = async (data) => {
        let result = await apiRequest("POST", `appuser/signupComplete`, data)
        if (result.error) {
            setAlertMessage(result.error)
        }
        //else setLoadingSuccessData({message:"You have signed up successfully"})

        return result
    }

    const loginAnonymous = async (data) => {



        let result = await apiRequest("POST", `appuser/loginAnonymous`, data)
        if (result.error) {
            setAlertMessage(result.error)
        }

        return result
    }

    const loginAnonymousComplete = async (data) => {

        let result = await apiRequest("POST", `appuser/loginAnonymousComplete`, data)
        if (result.error) {
            setAlertMessage(result.error)
        }

        return result
    }

    const login = async (data) => {
        let result = await apiRequest("POST", `appuser/login`, data)
        if (result.error) {
            setAlertMessage(result.error)
        }
        //else setLoadingSuccessData({message:"You have signed up successfully"})

        return result
    }

    const loginComplete = async (data, showSuccess = false) => {
        let result = await apiRequest("POST", `appuser/loginComplete`, data)
        if (result.error) {
            setAlertMessage(result.error)
        }
        else if (showSuccess) setLoadingSuccessData({ message: "You have logged in successfully" })

        return result
    }

    const verifyUser = async (data) => {
        let result = await apiRequest("POST", `appuser/verify`, data)
        if (result.error) {
            setAlertMessage(result.error)
        }
        //else setLoadingSuccessData({message:"You have signed up successfully"})

        return result
    }

    const verifyUserComplete = async (data, showSuccess = false) => {
        let result = await apiRequest("POST", `appuser/verifyComplete`, data)
        if (result.error) {
            setAlertMessage(result.error)
        }
        else if (showSuccess) setLoadingSuccessData({ message: "Your account has been verified successfully" })

        return result
    }


    async function addPasskey(handle, appId) {
        try {

            let response = await apiRequest("POST", "appuser/addPasskey", { handle: handle, appId: appId }, false)

            return response;

        } catch (error) {
            console.error(error)
            return { error: error }
        }
    }




    async function addPasskeyComplete(authData) {
        try {

            let response = await apiRequest("POST", "appuser/addPasskeyComplete", authData, false)

            console.log("addPasskeyComplete response = ", response)
            if (response.error) {
                return response
            }
            else {

                return response;
            }

        } catch (error) {
            console.error(error)
            return { error: error }
        }
    }




    async function resetAppUserPasskey(authData) {
        try {

            let response = await apiRequest("POST", "appuser/resetAppUserPasskey", authData, false)

            console.log("resetAppUserPasskey response = ", response)
            if (response.error) {
                return response
            }
            else {

                return response;
            }

        } catch (error) {
            console.error(error)
            return { error: error }
        }
    }

    async function importAppDatabase(data, file) {

        return await apiRequest("POST", "app/importApp", data, true, file)
    }

    async function exportAppDatabase(data) {
        setLoading(true)

        const catche = localStorage.getItem("appkey.io.user");
        let user = JSON.parse(catche);
        let requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "access-token": user["access-token"]
            },
            responseType: 'blob'
        };

        let endpoint = `${config.REST_API}/api/app/export/${data.appId}`
        const response = await fetch(endpoint, requestOptions);
        console.log("export response ", response)

        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let today = new Date();
        let todayString = today.getDate() + "-" + months[today.getMonth()] + "-" + today.getFullYear();
        const blobFile = await response.blob();
        // const blobFile = new Blob([response]);
        const url = window.URL.createObjectURL(blobFile);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${data.displayAppId}-database-export-${todayString}.zip`;
        a.click();
        window.URL.revokeObjectURL(url);


        a.remove(); // remove the element

        setLoading(false)

        return true;

    }


    function isLoggedIn() {
        try {
            const catche = localStorage.getItem("appkey.io.user");
            let loggedInUser = JSON.parse(catche);
            if (loggedInUser && loggedInUser['access-token']) {

                return true
            }
            else return false
        } catch (error) {
            return false
        }
    }


    const value = {
        loading,
        allApps,
        currentApp,
        loggedInUser,
        requestError,
        requestSuccess,
        alertMessage,
        loadingSuccessData,
        isLoggedIn,
        setLoadingSuccessData,
        setRequestError,
        setRequestSuccess,
        setAlertMessage,
        setCurrentApp,
        getAllApp,
        getApp,
        createApp,
        updateApp,
        deleteApp,
        resetApp,
        testAppEmailExt,
        testAppSMSExt,
        createAppInvite,
        apiRequest,
        createAppSignup,
        getAppSignup,
        deleteAppSignup,
        deleteAppUser,
        updateAppUserStatus,
        getAppEmailTemplate,
        updateEmailTemplate,
        updateAppLocale,
        createUserSignup,
        verifiyUserSignup,
        signupComplete,
        login,
        loginAnonymous,
        loginAnonymousComplete,
        loginComplete,
        verifyUserComplete,
        verifyUser,
        getAppUser,
        appLogs,
        addPasskeyComplete,
        addPasskey,
        resetAppUserPasskey,
        checkURL,
        exportAppDatabase,
        importAppDatabase
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )

}