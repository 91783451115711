


import React, { useRef, useState, useEffect } from "react"

import { useAuth } from "../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import { platformAuthenticatorIsAvailable, startAuthentication, browserSupportsWebAuthn, startRegistration } from '@simplewebauthn/browser';
// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

// Authentication pages components
import AuthLayout from "components/Layouts/AuthLayout";

function SignIn() {

  const { login, loginComplete, addPasskey, addPasskeyComplete, isLoggedIn } = useAuth()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [isAddingNewPasskey, setIsAddingNewPasskey] = useState(false)
  const [data, setData] = useState({})
  const [challengeData, setChallengeData] = useState({})

  const navigate = useNavigate()


  useEffect(() => {
    if (!browserSupportsWebAuthn()) {
      setError('It seems this browser does not support Passkey Authentication.');
      return;
    }

    try {

      if (isLoggedIn()) {

        navigate("/apps")
        return;
      }
    } catch (error) {

    }



  }, [])

  const onKeyEnterDown = (event) => {
    if (event.keyCode == 13) getLoginChallenge()
  }

  const getLoginChallenge = async () => {
    try {

      setError()
      setChallengeData()

      if (!await platformAuthenticatorIsAvailable()) {
        setError("Your device doesn't have Passkey Authenticator. Please use any security key device to register.")
        return;
      }

      setLoading(true)


      if (data.email === undefined || data.email === "") {

        setError("Please enter an email")
        return;
      }

      let result = await login(data.email);
      if (result.error) {
        setError(result.error.message)
        return
      }
      if (result.requireAddPasskey) {
        setIsAddingNewPasskey(true)
        return;
      }

      setChallengeData(result)

      if (result.isOpeningAddNewPasskey) {
        setIsAddingNewPasskey(true)
      }
      else {
        challengeLogin(result);
        setLoading(false)
      }



    } catch (error) {
      console.log("getRegisterChallenge error ", error);
      if (error.message) {
        setError(error.message)
      }

    }
    finally {
      setLoading(false)
    }
  }

  const challengeLogin = async (ch) => {
    try {
      ch = ch ? ch : challengeData;
      if (!ch) return;
      let asseResp = await startAuthentication(ch);
      asseResp.email = data.email;


      let authn = await loginComplete(asseResp);
      if (authn.error) {
        setError(authn.error.message)
      }
      else {
        navigate("/apps")
      }
    } catch (error) {
      console.log("startAuthentication ", error)
    }

  }

  const onChangeValue = async (evt) => {

    setData({
      ...data,
      [evt.target.name]: evt.target.value
    })


  }


  const confirmAddPasskey = async () => {
    try {


      if (data.passkeyToken && data.passkeyToken !== "") {
        let result = await addPasskey(data.email, data.passkeyToken)
        if (result.error) {
          setError(result.error.message)
          return
        }

        let attResp = await startRegistration(result);

        console.log("confirmAddPasskey startRegistration attResp =  ", attResp)

        attResp.email = data.email;

        let user = await addPasskeyComplete(attResp, data.passkeyToken);
        if (user.error) {
          setError(user.error.message)
        }
        else {
          setIsAddingNewPasskey(false)
          getLoginChallenge()

        }
        console.log("confirmAddPasskey user =  ", user)

      }
      else {
        setError("Please enter passkey token")
      }

    } catch (error) {
      setError(error.message)
    }
  }


  return (
    <AuthLayout>
      <Card>
        <MKBox
          variant="contained"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MKTypography>

        </MKBox>

        {error &&
          <MKAlert color="error">
            Whoop!&nbsp; {error}
          </MKAlert>
        }

        {message &&
          <MKAlert color="info">
            {message}
          </MKAlert>
        }

        {isAddingNewPasskey ?
          <MKBox pt={4} pb={3} px={3}>

          </MKBox>

          :


          <MKBox pt={4} pb={3} px={3}>

            <MKBox  >
              <MKBox mb={2} autoComplete="off" action={getLoginChallenge}>

                <MKInput label="Email" name="email" fullWidth onChange={onChangeValue} autoFocus required autoComplete="new-password" onKeyDown={onKeyEnterDown} />

              </MKBox>

              <MKBox mt={4} mb={1} textAlign="center">
                {loading ?
                  <CircularProgress color="primary" />
                  :
                  <MKButton variant="contained" color="primary" fullWidth onClick={getLoginChallenge}>
                    sign in
                  </MKButton>
                }

              </MKBox>


              <MKBox mt={3} mb={1} textAlign="center">

                <MKTypography variant="button" color="text">
                  Don&apos;t have an account?{" "}
                  <MKTypography
                    component={Link}
                    to="/signup"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign up
                  </MKTypography>
                </MKTypography>
              </MKBox>

            </MKBox>

          </MKBox>

        }
      </Card>




      <Modal open={isAddingNewPasskey} onClose={() => setIsAddingNewPasskey(false)} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={isAddingNewPasskey} timeout={300}>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKBox
              sx={{ width: '95%' }}
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              borderWidth={2}
              bgColor="white"
              shadow="xl"
              textAlign="center"
            >


              <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5" color="info">Your Account is opening to add new Passkey</MKTypography>
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => setIsAddingNewPasskey(false)} />
              </MKBox>

              <Grid container item xs={12} lg={8} sx={{ mx: "auto" }}>
                <MKBox width="100%" component="form" method="post" autocomplete="off">
                  <MKBox p={3}>

                    {error &&
                      <MKAlert color="error">
                        Whoop!&nbsp; {error}
                      </MKAlert>
                    }

                    <MKTypography variant="h5">Please enter Passkey token to add new passkey.</MKTypography>

                    <MKInput multiline maxRows={4} label="Passkey Token" name="passkeyToken" fullWidth required variant="standard" onChange={onChangeValue} />

                  </MKBox>

                </MKBox>
              </Grid>

              <Divider sx={{ my: 0 }} />

              <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">Click Login button to continue to login.</MKTypography>
              </MKBox>


              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={() => { setIsAddingNewPasskey(false); challengeLogin(challengeData) }}>
                  Login
                </MKButton>

                <MKButton type="submit" variant="gradient" color="primary" onClick={confirmAddPasskey}>
                  Submit
                </MKButton>

              </MKBox>

            </MKBox>
          </Grid>
        </Slide>
      </Modal>


    </AuthLayout>
  );
}

export default SignIn;
