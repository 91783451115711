/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useRef, useEffect } from "react";
import Stack from '@mui/material/Stack';
// @mui material components
import FileUpload from '@mui/icons-material/FileUpload';
import ButtonGroup from '@mui/material/ButtonGroup';
import Icon from "@mui/material/Icon";
import Slide from "@mui/material/Slide";
import Modal from "@mui/material/Modal";
import MKInput from "components/MKInput";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { Link, useNavigate } from "react-router-dom"
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import MKAlert from "components/MKAlert";
import CircularProgress from '@mui/material/CircularProgress';
import {startAuthentication} from '@simplewebauthn/browser';

// Authentication pages components
import AppLayout from "components/Layouts/AppLayout";
import { useAuth } from "../contexts/AuthContext"
import { appAuth } from "../contexts/AppContext"


function Apps() {

  const navigate = useNavigate()
  const { allApps, getAllApp, createApp, isLoggedIn, importAppDatabase } = appAuth()
  const { verify, verifyComplete } = useAuth()
  const appNameRef = useRef()


  const [modalState, setModalState] = useState({ createApp: false, showPlan: false });
  const [loadingApp, setLoadingApp] = useState(false);
  const [selectedFile, setSelectedFile] = useState();

  const [modalData, setModalData] = useState({});

  const toggleModal = (type, close) => {

    if (close) {
      setModalState({ ...modalState, [type]: !modalState[type], [close]: !modalState[close] });
    }
    else setModalState({ ...modalState, [type]: !modalState[type] });
  }

  const [errorImport, setErrorImport] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const renderRef = useRef(false)

  useEffect(() => {


    if (!isLoggedIn()) {
      navigate("/signin")
      return
    }

    if (renderRef.current === false) {

      getAllApp()

      return () => {
        renderRef.current = true
        console.log("Apps render clean up. ")
      }

    }

  }, []);




  async function gotoApp(app) {
    navigate(`/app/${app.appId}`)
  }



  const verifyPasskey = async () => {
    try {

      let challenge = await verify();
      if (challenge.error) {
        return;
      }

      let asseResp = await startAuthentication(challenge);
      let authn = await verifyComplete(asseResp);
      if (authn.error) {
        setErrorMessage(`Whoop! ${authn.error.message}.`)
        return false
      }

      return true;
    } catch (error) {
      return false;
    }
  }

  async function importApplication() {
    setErrorImport("");

    if(appNameRef.current.value == "" || !appNameRef.current.value) {
      setErrorImport("App name is required.");
      
      return;
    }
    else if (allApps.filter(app => app.name === appNameRef.current.value)[0]){
      setErrorImport("App name is already used.");
      return;
    } 
   

    let passedVerify = await verifyPasskey();
    if(!passedVerify) return;
    
    let result = await importAppDatabase({appName:appNameRef.current.value}, selectedFile)
    if(result.error){
      setErrorImport(result.error.message)
    }
    else {
      getAllApp()
      toggleModal("importApp")
    }

    
  }

  async function onSelectedAppFile(evt) {
    console.log("onSelectedAppFile files ", evt.target.files)
    let file = evt.target.files[0];
    setSelectedFile(file)
  }

  async function createAppHandler() {


    setLoadingApp(true)
    toggleModal("createApp")
    let result = await createApp(appNameRef.current.value);
    setLoadingApp(false)

    console.log("createAppHandler result = ", result)

    if (result && result.error && result.error.message) {
      if (result.error.code === 417) {
        setModalData(result.error)
        toggleModal("showPlan", "createApp")
        return;
      }
      showError(result.error.message);
    }

  }

  function showError(message) {
    setErrorMessage(message);

    setTimeout(() => {
      setErrorMessage("");
    }, 7000);

  }

  const gotoProfilePlan = () => {
    toggleModal("createApp")
    navigate(`/profile?showPlan=true`)
  }


  const renderApps = () =>

    allApps.map(app => (

      <MKBox onClick={() => app.status !== "pending" && gotoApp(app)}
        key={app.appId}
        width="100%"
        height="5rem"
        variant="gradient"
        bgColor="info"
        color="white"
        coloredShadow="info"
        display="flex"
        alignItems="center"
        justifyContent="left"
        sx={{ cursor: "pointer" }}
        borderRadius="xl">
        <MKTypography variant="h5" color="white" px={2}>{app.name}</MKTypography>
        {app.status === "pending" && <div class="dotloader"></div>}

        <MKBox mx="auto" justifyContent="right" display="flex" width="100%" >

          <MKTypography variant="h6" pt={0.5}>Detail</MKTypography>
          <Icon fontSize="large" >arrow_right</Icon>
        </MKBox>
      </MKBox>

    ));



  return (
    <>
      <AppLayout>


        <Grid container justifyContent="center">
          <Grid item xs={12} md={11} lg={9}>

            {errorMessage != "" && <MKAlert color="error" dismissible>{errorMessage}</MKAlert>}

            <MKBox mx="auto" pb={2} display="flex" justifyContent="right" >
              {loadingApp ? <MKButton variant="gradient" color="info">
                loading... <CircularProgress color="primary" size={14} />
              </MKButton> :
                <ButtonGroup variant="contained" aria-label="Basic button group">
                  <MKButton variant="gradient" color="primary" onClick={() => toggleModal("createApp")}>
                    create new app
                  </MKButton>
                  <Tooltip title="restore application database" placement="top">
                    <MKButton
                      size="small" variant="info"
                      aria-haspopup="menu"
                      onClick={() => toggleModal("importApp")}
                    >
                      <FileUpload />
                    </MKButton>
                  </Tooltip>


                </ButtonGroup>
              }
            </MKBox>


            <Stack spacing={2} alignItems="center">
              {renderApps()}
            </Stack>


          </Grid>
        </Grid>



        <Modal open={modalState.showPlan} onClose={() => toggleModal("showPlan")} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={modalState.showPlan} timeout={300}>
            <Grid container item xs={12} lg={5} justifyContent="center" mx="auto">
              <MKBox
                position="relative"
                sx={{ width: '95%' }}
                display="flex"
                flexDirection="column"
                borderRadius="xl"
                bgColor="white"
                shadow="xl"
              >

                <MKBox display="flex" alignitems="center" justifyContent="space-between" p={2}>
                  <MKTypography variant="h5">Required Account Upgrade</MKTypography>
                  <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => toggleModal("showPlan")} />
                </MKBox>

                <Divider sx={{ my: 0 }} />

                <MKBox display="flex" justifyContent="center" p={2}>
                  <MKTypography variant="h6">{modalData.message}</MKTypography>
                </MKBox>

                <Divider sx={{ my: 0 }} />
                <MKBox display="flex" justifyContent="space-between" p={1.5}>
                  <MKButton variant="gradient" color="dark" onClick={() => toggleModal("showPlan")}>
                    close
                  </MKButton>
                  <MKButton variant="gradient" color="primary" onClick={gotoProfilePlan}>
                    Upgrade
                  </MKButton>
                </MKBox>

              </MKBox>
            </Grid>
          </Slide>
        </Modal>


        <Modal open={modalState.createApp} onClose={() => toggleModal("createApp")} sx={{ display: "grid", placeItems: "center" }}>

          <MKBox
            position="relative"
            sx={{ minWidth: 300, maxWidth: 'lg' }}
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">Create Application</MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => toggleModal("createApp")} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox p={2}>
              <MKInput
                id="appNameRef"
                variant="standard"
                label="App Name"
                inputRef={appNameRef}
                required
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </MKBox>

            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="dark" onClick={() => toggleModal("createApp")}>
                close
              </MKButton>
              <MKButton variant="gradient" color="primary" onClick={createAppHandler}>
                Create
              </MKButton>
            </MKBox>
          </MKBox>

        </Modal>



        <Modal open={modalState.importApp} onClose={() => toggleModal("importApp")} sx={{ display: "grid", placeItems: "center" }}>

          <MKBox
            position="relative"
            sx={{ minWidth: 300, maxWidth: 'lg' }}
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">Import Application</MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => {setErrorImport(""), toggleModal("importApp") }} />
            </MKBox>
            <MKBox p={2}>
              {errorImport != "" && <MKAlert color="error" px={2} >{errorImport}</MKAlert>}
            </MKBox>
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="body">Please enter app name and select an application zip file to restore.</MKTypography>
             
            </MKBox>
            <Divider sx={{ my: 0 }} />

            <MKBox p={2}>
              <MKInput
                  id="appNameRef"
                  variant="standard"
                  label="App Name"
                  inputRef={appNameRef}
                  required
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                /> 

            </MKBox>

            <MKBox p={2}>
              <input type="file" name="appZipFile" accept=".zip" onChange={onSelectedAppFile} /> 
            </MKBox>

            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="dark" onClick={() => {setErrorImport(""), toggleModal("importApp") }}>
                close
              </MKButton>
              {selectedFile && <MKButton variant="gradient" color="primary" onClick={importApplication}>
                Submit
              </MKButton>}
            </MKBox>
          </MKBox>

        </Modal>


      </AppLayout>
    </>

  );


}

export default Apps;
