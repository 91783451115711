
import { useState, useEffect, useRef } from "react";
import MKTypography from "components/MKTypography";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
 
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
 

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
 
import MKAlert from "components/MKAlert";
import MKBadge from "components/MKBadge";

import Pagination from '@mui/material/Pagination';
import { startAuthentication } from '@simplewebauthn/browser';

import Dropdowns from "components/AppKey/Dropdowns";

import { useAuthAdmin } from "../contexts/AuthAdminContext"
import AdminLayout from "components/Layouts/AdminLayout";
import { useNavigate } from "react-router-dom"

export default function Admin() {


  const navigate = useNavigate()
  const { verify, verifyComplete, getUsers, getSignups, getUserApps, getApps, updateUser, resetUserPasskey } = useAuthAdmin()

  const [filter, setFilter] = useState({ search: "", limit: 25, offset: 0 })
  const [search, setSearch] = useState("")

  const [signupData, setSignupData] = useState([]);
  const [signupDataRender, setSignupDataRender] = useState([]);

  const [userData, setUserData] = useState([]);
  const [userDataRender, setUserDataRender] = useState([]);

  const [appData, setAppData] = useState([]);
  const [appDataRender, setAppDataRender] = useState([]);

  const [appUserData, setAppUserData] = useState([]);
  const [appUserDataRender, setAppUserDataRender] = useState([]);

  const [message, setMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const [currentAdmin, setCurrentAdmin] = useState({})
  const [currentUser, setCurrentUser] = useState({})
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [total, setTotal] = useState();

  const [modalState, setModalState] = useState({
    activeTabUser: 0, 
    showVerify: false, 
    user: {} 
  });



  const userAppTableHeader = [

    { id: "appId", name: "appId", align: "center" },
    { id: "displayAppId", name: "displayAppId", align: "center" },
    { id: "name", name: "name", align: "center" },
    { id: "status", name: "status", align: "center" },
    { id: "handleType", name: "handleType", align: "center" },
    { id: "totalUser", name: "totalUser", align: "center" },
    { id: "created", name: "created", align: "center" },

  ]


  const appTableHeader = [


    { id: "displayAppId", name: "displayAppId", align: "center" },
    { id: "name", name: "name", align: "center" },
    { id: "status", name: "status", align: "center" },

    { id: "email", name: "email", align: "center" },
    { id: "totalUser", name: "totalUser", align: "center" },
    { id: "created", name: "created", align: "center" },

  ]

  let userTableHeader = [
    { id: "number", name: "no", align: "left", maxWidth: 100 },
    { id: "email", name: "email", align: "center" },
    { id: "firstName", name: "firstName", align: "center" },
    { id: "lastName", name: "lastName", align: "center" },
    { id: "status", name: "status", align: "center" },
    { id: "plan", name: "plan", align: "center" },
    { id: "apps", name: "apps", align: "center" },
    { id: "created", name: "created", align: "center" },
    { id: "control", name: "control", align: "center" },
  ]


  let signupTableHeader = [
    { id: "number", name: "no", align: "left", maxWidth: 100 },
    { id: "email", name: "email", align: "center" },
    { id: "firstName", name: "firstName", align: "center" },
    { id: "lastName", name: "lastName", align: "center" },
    { id: "company", name: "company", align: "center" },
    { id: "status", name: "status", align: "center" },
    { id: "created", name: "created", align: "center" },
  ]



  const renderRef = useRef(false)

  useEffect(() => {

    applyFilter()

  }, [modalState.activeTabUser, page, rowsPerPage])

  useEffect(() => {

    async function fetchAppData() {
      getUserData()

    }

    if (renderRef.current === false) {

      const loggedInUser = JSON.parse(localStorage.getItem("appkey.io.user"));
      if (!loggedInUser || !loggedInUser.isAdmin) {
        navigate("/apps")
        return
      } 

      setCurrentAdmin(loggedInUser)
      fetchAppData()


      return () => {
        renderRef.current = true
        console.log("render clean up. ")
      }
    }

  }, [])



  const applyFilter = async () => {
    resetMessage()

    if (modalState.activeTabUser === 0) getUserData()
    else if (modalState.activeTabUser === 1) getSignupData()
    else if (modalState.activeTabUser === 2) getAppsData()
    else if (modalState.activeTabUser === 3) getApplicationUser()

  }


  const getAppsData = async () => {

    let result = await getApps(filter)

    setAppData(prevItems => {
      return [];
    });

    setAppDataRender(prevItems => {
      return [];
    }); 
    if (!result || !result.data) return

    setTotal(result.total || 0)

    for (let index = 0; index < result.data.length; index++) { 
      let element = makeAppRecord(result.data[index], index);
      element.number = index + 1 

      setAppData(prevItems => {
        return [...prevItems, element];
      });

      setAppDataRender(prevItems => {
        return [...prevItems, element];
      });
 
    }

  }


  const getSignupData = async () => {


    let result = await getSignups(filter)

    setSignupData(prevItems => {
      return [];
    });

    setSignupDataRender(prevItems => {
      return [];
    }); 

    if (!result || !result.data) return

    setTotal(result.total || 0)

    for (let index = 0; index < result.data.length; index++) { 
      let element = makeSignupRecord(result.data[index], index);
      element.number = index + 1 
      setSignupData(prevItems => {
        return [...prevItems, element];
      });

      setSignupDataRender(prevItems => {
        return [...prevItems, element];
      });


    }
  }


  const getUserData = async () => {
    let result = await getUsers(filter)

    console.log("getUserData result = ", result)

    setUserData(prevItems => {
      return [];
    });

    setUserDataRender(prevItems => {
      return [];
    });

    if (!result || !result.data) return

    setTotal(result.total || 0)

    for (let index = 0; index < result.data.length; index++) {

      let element = makeUserRecord(result.data[index]);
      element.number = index + 1

      setUserData(prevItems => {
        return [...prevItems, element];
      });

      setUserDataRender(prevItems => {
        return [...prevItems, element];
      });


    }
  }



  const handleTabUserType = (event, newValue) => {

    console.log("handleTabUserType newValue = ", newValue)
    setModalState({ ...modalState, ["activeTabUser"]: newValue });
  }

  function onKeyEnterSearch(event) {
    if (event.keyCode == 13) applyFilter()
  }

  function onSearchRecord(evt) {
    let value = evt.target.value
    setSearch(value)

    setFilter({
      ...filter,
      ["search"]: search
    });


    console.log("onSearchRecord value ", value)
    if (modalState.activeTabUser === 0) {
      setUserDataRender(
        userData.filter(data =>
          data.email.indexOf(value) >= 0 || data.firstName.indexOf(value) >= 0 || data.lastName.indexOf(value) >= 0
        ));

    }
    else if (modalState.activeTabUser === 1) {
      setSignupDataRender(
        signupData.filter(data =>
          data.email.indexOf(value) >= 0 || data.firstName.indexOf(value) >= 0 || data.lastName.indexOf(value) >= 0
        ));

    }
    else if (modalState.activeTabUser === 2) {
      setAppDataRender(
        appData.filter(data =>
          data.name.indexOf(value) >= 0 || data.displayAppId.indexOf(value) >= 0 || data.email.indexOf(value) >= 0
        ));

    }

    if (value === "") applyFilter()

  }



  const verifyPasskey = async () => {
    try {

      let challenge = await verify();
      if (challenge.error) {
        setErrorMessage(`Whoop! ${challenge.error.message}.`)
        return false
      }

      let asseResp = await startAuthentication(challenge);
      let authn = await verifyComplete(asseResp);
      if (authn.error) {
        setErrorMessage(`Whoop! ${authn.error.message}.`)
        return false
      }

      return true;
    } catch (error) {
      return false;
    }
  }



  const formatDate = (dateString) => {
    const options = { year: "2-digit", month: "2-digit", day: "2-digit", hour: 'numeric', hour12: true }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  const formatFullDate = (dateString) => {
    const options = { year: "2-digit", month: "2-digit", day: "2-digit", hour: 'numeric', hour12: true, minute: 'numeric' }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  const makeSignupRecord = (item) => {
    item.created = formatDate(item.createdAt)
    item.updated = formatDate(item.updatedAt)
    item.hide = false
    return item

  }


  const makeAppRecord = (item) => {
    item.created = formatDate(item.createdAt)
    item.updated = formatDate(item.updatedAt)
    item.hide = false
    return item

  }

  const showUserApps = async (user) => {

    setCurrentUser(user)

    handleTabUserType(null, 3)

    getApplicationUser(user)
  }

  const getApplicationUser = async (user) => {

    user = user ? user : currentUser;
    if (user === undefined) return;
    let apps = await getUserApps(user)

    setAppUserData(prevItems => {
      return [];
    });

    setAppUserDataRender(prevItems => {
      return [];
    });

    if (!apps || apps.length == 0) return

    setTotal(apps.length || 0)

    for (let index = 0; index < apps.length; index++) {

      let element = makeAppUserRecord(apps[index]);
      element.number = index + 1

      setAppUserData(prevItems => {
        return [...prevItems, element];
      });

      setAppUserDataRender(prevItems => {
        return [...prevItems, element];
      });
    }
  }

  const makeUserRecord = (item) => {

    item.created = formatDate(item.createdAt)
    item.updated = formatDate(item.updatedAt)


    if (item.apps) {

      let color = item.apps <= 5 ? "info" : "danger";
      item.apps = <MKBadge sx={{ "&:hover": { cursor: "pointer" } }} variant="contained" badgeContent={item.apps} color={color} size="xs" container onClick={() => showUserApps(item)} />

    }

    item.plan = item.planId.indexOf("plus") > 0 ? "Developer Plus" : item.planId.indexOf("tested") > 0 ? "Tester" : item.planId.indexOf("developer") > 0 ? "Developer" : "Free"
    item.hide = false
    return item

  }



  const makeAppUserRecord = (item) => {

    item.created = formatDate(item.createdAt)
    item.updated = formatDate(item.updatedAt)
    item.hide = false
    return item

  }



  const handleChangeRowsPerPage = (value) => {

    console.log("handleChangeRowsPerPage newPage ", value);
    setFilter({
      ...filter,
      ["limit"]: value,
      ["offset"]: 1
    });
    setRowsPerPage(value);
    setPage(1);

  };


  const handleChangePage = (event, newPage) => {
    console.log("handleChangePage newPage ", newPage);
    setPage(newPage);
    setFilter({
      ...filter,
      ["offset"]: newPage
    });


  };

  const updateUserStatus = async (user) => {
    resetMessage()
    let check = await verifyPasskey();
    if (!check) return;
    let status = user.status == 'active' ? 'suspended' : 'active';
    let data = { userId: user.userId, status: status }
    let result = await updateUser(data)

    if (result.error) setErrorMessage(`Whoop! ${result.error.message}.`)
    else {
      setMessage("User status has been updated.")
      setUserDataRender(userDataRender.map(item => {
        if (item.userId === user.userId) return { ...item, ["status"]: status }
        else return item
      }))

      setUserData(userData.map(item => {
        if (item.userId === user.userId) return { ...item, ["status"]: status }
        else return item
      }))
    }
  }



  const handleResetUserPasskey = async (user) => {
    resetMessage()
    let check = await verifyPasskey();
    if (!check) return;

    let result = await resetUserPasskey(user)
    if (result.error) setErrorMessage(`Whoop! ${result.error.message}.`)
    else setMessage("User passkey has been reset.")
  }


  const resetMessage = () => {
    setMessage()
    setErrorMessage()
  }


  return (
    <>
      <AdminLayout>
        <MKBox p={2}>
          <MKTypography variant="h5" color="black">AppKey Users</MKTypography>

          <MKBox xs={12} display="flex" justifyContent={"space-between"} py={3}>

            <Grid container  >
              <Grid item xs={12} md={6} pb={2}>
                <AppBar position="static">
                  <Tabs value={modalState.activeTabUser} onChange={handleTabUserType}>
                    <Tab label="Users" value={0} />
                    <Tab label="Signups" value={1} />
                    <Tab label="Apps" value={2} />
                  </Tabs>
                </AppBar>
              </Grid>
              <Grid item xs={12} md={6} textAlign="right" >
                <MKInput
                  variant="standard"
                  placeholder="Search"
                  id="searchValue"
                  name="searchValue"
                  autoFocus={true}
                  value={search}
                  onKeyDown={onKeyEnterSearch}
                  onChange={e => { onSearchRecord(e) }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }} />
              </Grid>
            </Grid>
          </MKBox>

          <MKBox xs={12} display="flex" justifyContent={"space-between"} py={3}>
            <Grid container  >
              <Grid item xs={12} md={6} pb={2}>
                <MKBox display="flex" alignItems="center" >
                  <MKBox alignItems="center" justifyContent="left" display="flex" >

                    <MKTypography variant="body2" color="text">  Show:  </MKTypography>
                    <Dropdowns items={[25, 100, 150]} defaultValue={rowsPerPage} onChange={handleChangeRowsPerPage} />
                    <MKTypography variant="body2" color="text"> entries  </MKTypography>

                    <MKBox px={3}>
                      <MKTypography variant="body2" color="text"> Total Record: {total}  </MKTypography>
                    </MKBox>
                  </MKBox>

                </MKBox>
              </Grid>

            </Grid>
          </MKBox>

          <CustomTabPanel value={modalState.activeTabUser} index={0}>
            <MKBox display="flex" alignItems="center" >
              {message ? <MKAlert color="primary" >{message}</MKAlert> : errorMessage ? <MKAlert color="error">{errorMessage}</MKAlert> : null}
            </MKBox>

            <TableContainer sx={{ maxHeight: 650 }}>
              <Table responsive="sm">
                <thead>
                  <TableRow>
                    {userTableHeader.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ maxWidth: column.maxWidth }}
                        className="colunmHeader"
                      >
                        {column.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </thead>
                <TableBody>
                  {userDataRender
                    //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={user.number}>
                          {userTableHeader.map((column) => {

                            let value = user[column.id];

                            if (column.id === "status") {

                              return (
                                <TableCell key={column.id} align={column.align} className="tableCell">
                                  {value === "active" ? <MKBadge variant="contained" badgeContent={value} color="success" size="xs" container /> :
                                    <MKBadge variant="contained" badgeContent={value} color="error" size="xs" container />
                                  }

                                </TableCell>
                              );
                            }

                            else if (column.id === "control") {
                              return (
                                <TableCell key={column.id} align={column.align} className="tableCell">
                                  {user.email != currentAdmin.email ? <MKBox>

                                    <Tooltip title="reset passkey" placement="top">
                                      <MKButton variant="text" color="info" onClick={() => handleResetUserPasskey(user)} iconOnly px={2} ><Icon>key</Icon></MKButton>
                                    </Tooltip>

                                    {user.status === "active" ?
                                      <Tooltip title="suspend this user" placement="top">
                                        <MKButton variant="text" color="error" onClick={() => updateUserStatus(user)} iconOnly px={2}><Icon>person_off</Icon></MKButton>
                                      </Tooltip> :
                                      <Tooltip title="activate this user" placement="top">
                                        <MKButton variant="text" color="info" iconOnly onClick={() => updateUserStatus(user)} px={2}><Icon>settings_accessibility</Icon></MKButton>
                                      </Tooltip>
                                    }

                                  </MKBox> : 
                                  <MKBadge variant="contained" badgeContent= "Administrator" color="info" size="xs" container />
                                  }
                                </TableCell>
                              );
                            }
                            else {


                              return (
                                <TableCell key={column.id} align={column.align} className="tableCell">
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })}

                </TableBody>


              </Table>
            </TableContainer>


            {Math.ceil(total / rowsPerPage) > 1 &&
              <Grid container item xs={12} lg={5} justifyContent="center" mx="auto" py={3}>
                <Pagination count={Math.ceil(total / rowsPerPage)} showFirstButton showLastButton page={page} onChange={handleChangePage} />
              </Grid>
            }
          </CustomTabPanel>


          <CustomTabPanel value={modalState.activeTabUser} index={1}>
            <MKBox display="flex" alignItems="center" >
              {message ? <MKAlert color="primary" >{message}</MKAlert> : errorMessage ? <MKAlert color="error">{errorMessage}</MKAlert> : null}
            </MKBox>

            <TableContainer sx={{ maxHeight: 650 }}>
              <Table responsive="sm">
                <thead>
                  <TableRow>
                    {signupTableHeader.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ maxWidth: column.maxWidth }}
                        className="colunmHeader"
                      >
                        {column.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </thead>
                <TableBody>
                  {signupDataRender
                    //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={user.number}>
                          {signupTableHeader.map((column) => {

                            let value = user[column.id];

                            if (column.id === "status") {

                              return (
                                <TableCell key={column.id} align={column.align} className="tableCell">
                                  {value === "active" ? <MKBadge variant="contained" badgeContent={value} color="success" size="xs" container /> :
                                    <MKBadge variant="contained" badgeContent={value} color="error" size="xs" container />
                                  }

                                </TableCell>
                              );
                            }
                            else {


                              return (
                                <TableCell key={column.id} align={column.align} className="tableCell">
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })}

                </TableBody>


              </Table>
            </TableContainer>

            {Math.ceil(total / rowsPerPage) > 1 &&
              <Grid container item xs={12} lg={5} justifyContent="center" mx="auto" py={3}>
                <Pagination count={Math.ceil(total / rowsPerPage)} showFirstButton showLastButton page={page} onChange={handleChangePage} />
              </Grid>
            }

          </CustomTabPanel>

          <CustomTabPanel value={modalState.activeTabUser} index={2}>
            <MKBox display="flex" alignItems="center" >
              {message ? <MKAlert color="primary" >{message}</MKAlert> : errorMessage ? <MKAlert color="error">{errorMessage}</MKAlert> : null}
            </MKBox>

            <TableContainer sx={{ maxHeight: 650 }}>
              <Table responsive="sm">
                <thead>
                  <TableRow>
                    {appTableHeader.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ maxWidth: column.maxWidth }}
                        className="colunmHeader"
                      >
                        {column.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </thead>
                <TableBody>
                  {appDataRender
                    //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={user.number}>
                          {appTableHeader.map((column) => {

                            let value = user[column.id];

                            if (column.id === "status") {

                              return (
                                <TableCell key={column.id} align={column.align} className="tableCell">
                                  {value === "active" ? <MKBadge variant="contained" badgeContent={value} color="success" size="xs" container /> :
                                    <MKBadge variant="contained" badgeContent={value} color="error" size="xs" container />
                                  }

                                </TableCell>
                              );
                            }
                            else {


                              return (
                                <TableCell key={column.id} align={column.align} className="tableCell">
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })}

                </TableBody>


              </Table>
            </TableContainer>

            {Math.ceil(total / rowsPerPage) > 1 &&
              <Grid container item xs={12} lg={5} justifyContent="center" mx="auto" py={3}>
                <Pagination count={Math.ceil(total / rowsPerPage)} showFirstButton showLastButton page={page} onChange={handleChangePage} />
              </Grid>
            }
          </CustomTabPanel>



          <CustomTabPanel value={modalState.activeTabUser} index={3}>
            <MKBox display="flex" alignItems="center" >
              {message ? <MKAlert color="primary" >{message}</MKAlert> : errorMessage ? <MKAlert color="error">{errorMessage}</MKAlert> : null}
            </MKBox>

            <TableContainer sx={{ maxHeight: 650 }}>
              <Table responsive="sm">
                <thead>
                  <TableRow>
                    {userAppTableHeader.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ maxWidth: column.maxWidth }}
                        className="colunmHeader"
                      >
                        {column.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </thead>
                <TableBody>
                  {appUserDataRender
                    //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={user.number}>
                          {userAppTableHeader.map((column) => {

                            let value = user[column.id];

                            if (column.id === "status") {

                              return (
                                <TableCell key={column.id} align={column.align} className="tableCell">
                                  {value === "active" ? <MKBadge variant="contained" badgeContent={value} color="success" size="xs" container /> :
                                    <MKBadge variant="contained" badgeContent={value} color="error" size="xs" container />
                                  }

                                </TableCell>
                              );
                            }
                            else {


                              return (
                                <TableCell key={column.id} align={column.align} className="tableCell">
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })}

                </TableBody>


              </Table>
            </TableContainer>
          </CustomTabPanel>

        </MKBox>

      </AdminLayout>
    </>
  )


  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <MKBox
        role="tabpanel"
        hidden={value !== index}
        id={`app-tabpanel-${index}`}
        aria-labelledby={`app-tab-${index}`}
        {...other}
      >
        {value === index && <MKBox>{children}</MKBox>}
      </MKBox>
    );
  }

}